import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import TablasMultiplicar from "./pages/TablasMultiplicar";
import Ingles from "./pages/Ingles";
import Multiplicaciones from "./pages/Multiplicaciones/Multiplicaciones";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/tablasmultiplicar" element={<TablasMultiplicar />} />
      <Route path="/multiplicaciones" element={<Multiplicaciones />} />
      <Route path="/ingles" element={<Ingles />} />
    </Routes>
  );
}

export default App;
