import { Link } from "react-router-dom";

function TablasMultiplicar() {
  return (
    <>
      <p>Tablas de multiplicar</p>
      <Link to={"/"}>Home</Link>
    </>
  );
}

export default TablasMultiplicar;
