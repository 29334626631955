import { Link } from "react-router-dom";
import "./Home.css";

function Home() {
  return (
    <ul className="menu">
      <li className="multiplicaciones">
        <Link to={"/multiplicaciones"}>Multiplicaciones</Link>
      </li>
      <li className="ingles">
        <Link to={"/ingles"}>Inglés</Link>
      </li>
      <li className="tablas">
        <Link to={"/tablasmultiplicar"}>Tablas de multiplicar</Link>
      </li>
    </ul>
  );
}

export default Home;
