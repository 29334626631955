import { Link } from "react-router-dom";

function Ingles() {
  return (
    <>
      <p>Inglés</p>
      <Link to={"/"}>Home</Link>
    </>
  );
}

export default Ingles;
