import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faExchangeAlt } from "@fortawesome/fontawesome-free-solid";
import Button from "../components/Button/Button";
import "./Multiplicaciones.css";

function Multiplicaciones() {
  const [isCorrect, setIsCorrect] = useState(null);
  const [op1, setOp1] = useState(0);
  const [op2, setOp2] = useState(0);
  const [res, setRes] = useState(null);
  const [resUser, setResUser] = useState("");
  const navigate = useNavigate();

  function randomNum() {
    return Math.floor(Math.random() * 11);
  }

  const otra = useCallback(() => {
    setOp1(randomNum());
    setOp2(randomNum());
    setResUser("");
    setRes(null);
    setIsCorrect(null);
  }, []);

  const comprueba = useCallback(() => {
    if (resUser !== "") {
      if (Number(resUser) === op1 * op2) {
        setIsCorrect(true);
      } else {
        setIsCorrect(false);
      }
    }
  }, [op1, op2, resUser]);

  function resultado() {
    setRes(op1 * op2);
  }

  function linkTo() {
    navigate("/");
  }

  useEffect(() => {
    console.log("USE EFFECT COMPRUEBA");
    comprueba();
  }, [resUser, comprueba]);

  useEffect(() => {
    console.log("USE EFFECT OTRA");
    otra();
  }, [otra]);

  return (
    <div className="mults">
      <div className="contenedor">
        <div className="mult">
          <div>{op1}</div>
          <div>x</div>
          <div>{op2}</div>
          <div>=</div>
          <input
            className={isCorrect === null ? "" : isCorrect ? "ok" : "ko"}
            onChange={(e) => setResUser(e.target.value)}
            value={resUser}
            type={"number"}
          />
          <div>{res === null ? "" : res}</div>
          {/* <FontAwesomeIcon icon={faExchangeAlt} /> */}
        </div>
        <div className="buttons">
          <Button onClick={otra} label={"Otra"} />
          <Button onClick={resultado} label={"Resultado"} />
          <Button onClick={linkTo} label={"Inicio"} />
        </div>
      </div>
    </div>
  );
}

export default Multiplicaciones;
